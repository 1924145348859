.detail-content {
  width: 100%;
  background: rgb(245, 245, 245);
  border: 1px;
  padding: 0px 4rem;
}
@media screen and (min-width: 850px) {
  .detail-content {
    max-width: 80%;
  }
}
