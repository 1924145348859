@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Dosis:wght@200;300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400&display=swap");
.header {
  position: fixed;
  padding: 10px 0;
  top: 0;
  right: 0;
  z-index: 1;
  left: 0;
}
.header-bg {
  background-color: #e0ccf5;
  transition: 0.3s;
  box-shadow: 0px 1px 5px rgb(170, 168, 168);
}

a.active {
  font-weight: bold !important;
  color: #000 !important;
  letter-spacing: 0.3px;
}

.main_menu_child a {
  transition: color 0.3s ease;
}

.main_menu_child a:hover {
  color: #000; /* Active color on hover */
}

.main_section {
  top: 0;
  right: 0;
  left: 0;
  background-color: #ebeaec !important;
  z-index: 1;
  font-family: "Manrope", sans-serif;
}
.main_menu_child {
  padding: 0 1.3rem;
  font-size: 15.8px;
  font-family: "Manrope", sans-serif;
}
.logo img {
  width: 145px;
}
@media (max-width: 600px) {
  .logo img {
    width: 110px;
  }
  .btn-nav {
    padding: 5px 10px !important;
    margin-right: 49px;
    font-size: 12px;
  }
}
.i2m {
  font-size: 22px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
}
.logo_text {
  line-height: 23px;
  padding-left: 16px;
}
.welcome {
  font-size: 12px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
}
.btn-nav {
  border: 1px solid #8017ea;
  padding: 8px 20px;
  border-radius: 40px;
  margin-left: 14px;
  color: #8017ea;
  font-weight: 600;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
  font-family: "Manrope", sans-serif;
}

.main_menu_child a {
  text-decoration: none;
  color: #828385;
  font-family: "Manrope", sans-serif;

  font-weight: 700;
}
@media screen and (max-width: 1040px) {
  .main_menu {
    display: none !important;
  }
}
.main_menu {
  margin-left: 27px;
}
@media (max-width: 280px) {
  .take {
    padding: 3px 5px !important;
    font-size: 10px !important;
    margin-right: 32px;
  }
  .logo img {
    width: 90px;
  }
}
.take {
  transition: 0.5s;
}
.take:hover {
  background-color: #8017ea;
  color: white;
}
