@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Dosis:wght@200;300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400&display=swap");
.track_fast {
  font-family: "Manrope", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 160px 0 20px 0;
  align-items: center;
  justify-content: center;
}
.king {
  position: relative;
  background-color: #ebeaec;
}
.king::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background: linear-gradient(83.21deg, #ad6af0, #c493f5);
  -webkit-mask-image: radial-gradient(rgba(0, 0, 0, 0.5), transparent 75%);
  background-blend-mode: overlay;
}
.header_track h1 {
  font-size: 4rem !important;
  font-weight: 700;
  line-height: 63px !important;
}
.header_track .header_T2 {
  color: #828385;
}
@media (max-width: 600px) {
  .track_fast {
    margin-top: 20px;
    padding: 50px 0;
  }

  .card-ex {
    margin-top: 19px;
  }
  .choosing {
    font-size: 27px !important;
  }
  .header_track h1 {
    font-size: 26px !important;
    line-height: 24px !important;
    padding: 0 9px !important;
  }

  .track_paragraph {
    font-size: 15.5px !important;
    font-weight: 600 !important;
    font-family: "Quicksand", sans-serif;
  }
  .desktop-card {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .header_track h1 {
    font-size: 55px !important;
    line-height: 48px !important;
  }
  .header_track {
    padding-top: 80px !important;
  }
  .choosing {
    margin-bottom: 20px !important;
  }
  .choose-text1 {
    font-size: 18px !important;
  }

  .card-ex {
    min-height: 260px;
    width: 100% !important;
    margin-top: 45px;
  }
}
@media screen and (max-width: 920px) {
  .line_taker {
    display: none;
  }
}

.line_taker {
  height: 1px;
  background-color: #ac64f4;
  width: 100%;
}

.track_paragraph {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  padding: 30px 20px;
}

.centered-image-container img {
  width: 60% !important;
  overflow: hidden;
}

.centered-image-container {
  overflow: hidden;
}

@media screen and (max-width: 1040px) {
  .centered-image-container img {
    width: 90% !important;
  }
  .track_paragraph {
    font-size: 15px;
  }
  .track_fast {
    padding-top: 120px !important;
  }
  .mbm2 {
    width: 100% !important;
    overflow: hidden;
  }
}
.idea-card,
.company-card {
  border: 1px solid black;
  border-radius: 40px;
  overflow: hidden;
}
.card1 {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.main-div-card {
  margin-top: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  .desktop-card {
    display: none !important;
  }
}

.choose-text1 {
  font-size: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 700 !important;
  padding-bottom: -20px !important;
}
@media screen and (max-width: 280px) {
  .effect {
    font-size: 12px !important;
  }
  .effect2 {
    font-size: 10.2px !important;
  }
}
@media (max-width: 280px) {
  .small-dev {
    font-size: 8.3px !important;
    line-height: 12px !important;
  }
  .header_track h1 {
    font-size: 20px !important;
  }
  .track_paragraph p {
    font-size: 12px !important;
  }
}
@media (min-width: 992px) {
  .header_track h1 {
    font-size: 4.4rem !important;
    font-weight: 700;
    line-height: 69px !important;
  }
  .cardpara {
    font-weight: 500 !important;
  }
  .header_track {
    padding-top: 0px !important;
  }
  .card-ex {
    margin-top: 47px;
  }
  .header_track .header_T2 {
    color: #828385;
  }
  .choose-text1 {
    font-size: 26px !important;
  }
  .card-ex {
    min-height: 220px;
    width: 100% !important;
    margin-top: 45px;
  }
}

.small-dev {
  font-weight: 500;
}
.ex-img {
  width: 70px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.card-ex {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.choosing {
  font-size: 40px;
  font-weight: 700;
}
.choosing1 {
  font-family: "Manrope", sans-serif;
  margin-top: 130px;
}
.cardpara {
  font-family: "Manrope", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
}
/* new css */

@media (max-width: 600px) {
  .menu {
    margin-top: 15px;
  }
  .abs1 {
    margin-top: -18px !important;
  }
  .plus {
    color: #8017ea;
    font-weight: 700;
  }
  .spanking {
    font-weight: 600;
  }
}

@media screen and (min-width: 768px) {
  .spanking {
    font-size: 13px;
  }
  .plus {
    font-size: 22px !important;
    color: rgb(128, 23, 234);
    font-weight: bold;
  }
}
@media (min-width: 992px) {
  .plus {
    color: #8017ea;
    font-family: "Manrope", sans-serif !important;
    font-weight: 800;
    font-size: 36px !important;
  }
  .spanking {
    font-family: "Manrope", sans-serif !important;
    font-weight: 600;
    font-size: 16px;
  }
  .card {
    border: none;
    box-shadow: 0 4px 8px rgba(163, 163, 163, 0.1);
  }
}
.heck {
  font-family: "Manrope", sans-serif !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
.hack2 {
  font-family: "Manrope", sans-serif !important;
  font-weight: 600;
  font-size: 16px !important;
}
.wk {
  width: 17%;
}
