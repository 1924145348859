@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Dosis:wght@200;300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400&display=swap");

.footer-image {
  width: 127px;
}
footer {
  margin-top: 145px;
  font-family: "Manrope", sans-serif;
}
.footer-paragraph {
  font-weight: 500;
  font-size: 16px;
}
.copyright {
  margin-top: 70px;
  font-weight: 500;
  font-size: 14px;
  color: #828385;
}
.second-child p {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 16px !important;
  color: #828385;
}
.cnd a {
  color: black;
  font-weight: 600;
  text-decoration: none;
}
.second-child2 p {
  color: #828385;
  font-weight: 600;
}
.bi-facebook {
  width: 100px !important;
}
.second-child2 {
  padding-left: 80px;
}
.second-child {
  padding-left: 30px;
}
@media (max-width: 600px) {
  .copyright {
    display: none;
  }
  .chi {
    /*needed*/
    margin-top: -10px !important;
  }
  .acc {
    margin-top: 30px;
  }
  .i2mvp {
    /*needed*/
    font-size: 30px !important;
  }
}
@media (max-width: 280px) {
  .i2mvp {
    /*needed*/
    font-size: 21px !important;
  }
}

@media (min-width: 992px) {
  .dek {
    display: none;
  }
  .copyright {
    display: block !important;
  }
  .second-child2 {
    padding-left: 80px !important;
  }
  .acc {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .copyright {
    display: none;
  }
  .second-child2 {
    padding-left: 250px;
  }
  .acc {
    padding-top: 45px;
  }
}
.section-head {
  /*needed*/
  margin-bottom: 30px;
  margin-top: 80px;
}
.item {
  /*needed*/
  background-color: #fff;
  text-align: center;
  padding: 30px 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  margin-bottom: 25px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}
.item:hover {
  /*needed*/
  background-color: rgb(237, 228, 247);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0;
}

.item:hover h6,
.item:hover p {
  /*needed*/
  color: #000;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0;
}
.item .take-icon img {
  /*needed*/
  /*needed*/
  width: 50px !important;
  height: 50px !important;
  margin-bottom: 25px !important;
  line-height: 96px !important;
  border-radius: 50px !important;
}
.item p {
  /*needed*/
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;

  line-height: 26px;
}
.item h6 {
  /*needed*/
  margin-bottom: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 24px;
}
.section-head h4 {
  /*needed*/
  font-weight: 700;
  font-size: 40px;
  font-family: "Manrope", sans-serif;
}
.chi {
  /*needed*/
  overflow: hidden;
  margin-top: 77px;
}
.max_height {
  max-height: 60px;
  width: 60px !important;
  height: 60px;
  object-fit: contain;
  background-color: white;
  border-radius: 50%;
  padding: 11px;
  z-index: -1;
}
.testing {
  width: 65% !important;
  text-align: center !important;
  margin: auto;
}
@media (max-width: 376px) {
  .testing {
    width: 75% !important;
  }
}

.partner-slider {
  /*needed*/
  overflow: hidden !important;
}
.bi-facebook,
.bi-whatsapp,
.bi-linkedin {
  transition: 0.3s;
  cursor: pointer;
}
.bi-facebook:hover {
  color: #1877f2;
}
.bi-whatsapp:hover {
  color: #25d366;
}
.bi-linkedin:hover {
  color: #0077b5;
}
