@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Dosis:wght@200;300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400&display=swap");

.main-ready {
  font-family: "Quicksand", sans-serif;
}
.project {
  border-radius: 8px;
  height: 94px;
  overflow: hidden !important;
}

.project h4 {
  color: #8017ea;
  font-size: 30px;
  font-weight: 800;
}
.project-para {
  line-height: 7px;
  font-size: 15px;
  color: #26212c;
  font-weight: 600;
}
.ready-paragraph {
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
}
.choos-exp {
  background-color: #ebeaec;
  font-family: "Manrope", sans-serif;
  overflow: hidden !important;
}

.btn-nav2 {
  border: none;
  background-color: #fff;
  padding: 8px 28px;
  border-radius: 40px;
  margin-left: 14px;
  color: #ac64f4;
  border: 1px solid #ac64f4;
  font-weight: bold;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.2);
}
.ready-img img {
  width: 93% !important;
}

.company-card {
  border: none !important;
  border-radius: 15px !important;
}
.pad p {
  font-size: 14px;
}
@media (max-width: 600px) {
  .choos-exp {
    margin-top: -55px;
  }
  .kin {
    /*needed*/
    display: none;
  }
  .kup {
    /*needed*/
    width: 55% !important;
  }
  .minhih {
    /*needed*/
    min-height: 219px !important;
  }
  .phus {
    /*needed*/
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .phw {
    /*needed*/
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: "Manrope", sans-serif !important;
    padding: 0 5px;
  }
  .kin {
    /*needed*/
    width: 100px !important;
  }
  .fook {
    /*needed*/
    font-size: 23px !important;
    line-height: 32px !important;
  }
  .tik {
    text-align: center;
    padding-top: 12px;
  }
  .kit {
    min-height: 164px !important;
  }
  .ppp {
    font-weight: 700 !important;
  }
  .clean {
    /* needed */
    font-size: 23px;
    margin-top: -39px;
    margin-bottom: 39px;
    font-weight: 700;
  }
  .utilize-section {
    /*needed*/
    margin-top: -45px;
  }
  .ready-paragraph {
    font-size: 16.8px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 45px;
  }
  .startup {
    font-size: 19px !important;
  }
  .utilize-paragraph {
    /*needed*/
    font-weight: 600;
    color: #828385;
    font-size: 19px;
  }
  .utilize-main {
    /*needed*/
    padding-top: 149px !important;
  }
  .choose-head {
    font-size: 20px !important;
    margin-bottom: -10px;
  }

  .company .card p {
    line-height: 13px;
    height: 70px;
    font-size: 10px;
  }
  .pad {
    margin-bottom: 20px !important;
  }
  .padimg {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .pad {
    padding: 0 35px !important;
  }
  .kup {
    /*needed*/
    display: none;
  }
  .ppp {
    padding: 0 45px 0 45px;
  }
  .clean {
    /* needed */
    font-size: 30px !important;
    font-weight: 700;
  }
  .ready-paragraph {
    margin-bottom: 30px !important;
  }
  .mbm2 {
    display: none;
  }
  .phy {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
  }
  .phy2 {
    font-weight: 500;
    font-size: 16 !important;
  }

  .utilize-section {
    /*needed*/
    margin-top: 115px !important;
  }
  .len {
    width: 120px;
    height: 70px !important;
  }
  .len2 {
    height: 50px !important;
    width: 120px;
  }
  .dry {
    min-height: 206px;
  }
  .pig {
    min-height: 206px;
  }

  .taken {
    padding: 0 24px !important;
  }
}

.ready-img .mbml .mbm {
  overflow: hidden;
}
.mbml {
  margin-top: 75px;
}
#client {
  /* needed */
  background-color: #8017ea;
  font-family: "Manrope", sans-serif;
  overflow: hidden;
  margin-top: 105px;
  padding: 90px 0;
}

.company-card1 {
  border-radius: none !important;
  border: none !important;
}
.team-card {
  /*needed*/
  border: none !important;
  background-color: #e1d3f0 !important;
  border-radius: 15px !important;
}
.team-card img {
  /*needed*/
  width: 35%;
}
.footer-main {
  background-color: #1a1515d6;
}
@media (min-width: 768px) {
  .pad {
    margin-bottom: 20px;
  }
  .kup {
    /*needed*/
    display: none;
  }
  .kin {
    /*needed*/
    width: 50% !important;
  }
  .clean {
    /*needed*/
    text-align: center;
    font-size: 41px !important;
    font-weight: bold;
  }
  .utilize-paragraph {
    /*needed*/
    font-size: 20px;
    font-weight: bold;
    color: #828385;
    margin-top: 80px;
  }
  .company-card {
    min-height: 238px;
  }
  .project-para p {
    font-size: 12px;
    font-weight: bold;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .utilize-main {
    /*needed*/
    margin-top: 29px;
  }
  .tik {
    padding-top: 8px;
    padding-left: 12px;
  }

  .main-ready {
    margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .tik {
    display: flex !important;
  }
  .win2 {
    padding-left: 20px;
    margin-top: 22px;
  }
  .clean {
    /*needed*/
    text-align: left;
    font-size: 29px !important;
  }
  .utilize-paragraph {
    /*needed*/
    font-size: 31px !important;
    color: #828385;
    font-weight: 700;
    line-height: 43.75px;
    font-family: "Manrope", sans-serif !important;
    padding: 20px 0;
  }
}
.padimg:hover {
  background-color: #e6e6fa;
}

.choose-head {
  font-size: 29px;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
}

.client-main {
  padding-top: 45px;
  font-weight: 700;
}
.piggy-text {
  font-size: 20px !important;
  font-weight: bold;
  color: rgba(220, 50, 249, 0.53);
}
.startup {
  font-size: 21.5px;
  font-weight: 600 !important;
  color: white;
  padding-top: 65px;
}
#team {
  /*needed*/
  font-family: "Quicksand", sans-serif;
  overflow: hidden;
}
.team-head {
  /*needed*/
  padding-top: 80px;
  color: #ac64f4;
  font-weight: bold;
  font-size: 38px;
}
.cart-text {
  color: #ac64f4 !important;
  font-weight: bolder !important;
  letter-spacing: 1px;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #c4dbee;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #ac64f4, #ac64f4);
  border-radius: 40px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: green;
}
@media screen and (max-width: 992px) {
  .mbml {
    display: none;
  }
}
.experience-card {
  font-weight: 300;
  font-size: 15px;
}

.no_code {
  /*needed*/
  color: #000;
}
@media (max-width: 280px) {
  .ready-paragraph {
    font-size: 15px;
  }
  .utilize-paragraph {
    /*needed*/
    font-size: 12px;
  }
  .choose-head {
    font-size: 17px !important;
    margin-bottom: -26px;
  }

  .client-main {
    font-size: 20px;
  }

  .startup {
    font-size: 14px !important;
  }
  .team-head {
    /*needed*/
    font-size: 27px;
    margin-bottom: -23px;
  }
  .team-para-grap {
    /*needed*/
    font-size: 12px !important;
    margin-bottom: -30px;
  }
  .mobile-para-card {
    font-size: 13px;
  }
  .team-card {
    /*needed*/
    min-height: 200px !important;
  }
}
@media (max-width: 767px) {
  .mmm {
    border: none;
  }
}
@media (max-width: 600px) {
  .client-main {
    font-weight: 700 !important;
  }
  .small-dev {
    font-weight: 600 !important;
  }
}
.team-para-grap {
  /*needed*/
  font-size: 16.8px;
  font-weight: 600;
}
.win {
  /*needed*/
  justify-content: center !important;
}
.win1 {
  /*needed*/
  width: 150px !important;
}
.topp {
  /*needed*/
  display: flex;
  min-height: 194px;
  align-items: center;
}
.com-p {
  /*needed*/
  font-family: "Manrope", sans-serif !important;
  margin-top: -18px;
  font-size: 16px !important;
  font-weight: 600;
  padding: 0 5px !important;
}

.win2 {
  width: 200px !important;
  height: 80px !important;
}
.fook {
  /*needed*/
  color: #828385;
  font-size: 30px;
  line-height: 43.1px;
  font-weight: bold;
  font-family: "Manrope", sans-serif !important;
}
.foot-img {
  /*needed*/
  margin-top: 85px;
}
.west {
  /*needed*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left !important;
  line-height: 4px !important;
}
.west p {
  /*needed*/
  font-family: "Manrope", sans-serif !important;
  font-weight: bold;
  font-size: 20px;
}
.e2k {
  /*needed*/
  border-radius: 5px;
}
/*.e2k:hover {
  needed
  background-color: #d7b8f7 !important;
}*/

.foot-img2 {
  /*needed*/
  margin-bottom: 50px;
}
.teamd {
  /*needed*/
  font-size: 40px;
  font-weight: 700;
  padding: 77px 0 30px 0;
  font-family: "Manrope", sans-serif !important;
}
.card-photo {
  /*needed*/
  border-radius: 35px !important;
}
.kts h4 {
  /*needed*/
  font-size: 19px !important;
  font-weight: 700;
  font-family: "Manrope", sans-serif !important;
}
.kts .card {
  /*needed*/
  border: none;
  box-shadow: 0px 4px 8px rgba(156, 156, 156, 0.1);
}
.kts-p {
  /*needed*/
  font-size: 13px;
  font-family: "Manrope", sans-serif !important;
  color: #828385;
}
.kts-p1 {
  /*needed*/
  font-size: 14px;
  font-weight: 500;
  font-family: "Manrope", sans-serif !important;
}
.phw {
  /*needed*/
  font-family: "Manrope", sans-serif !important;
  font-size: 15px !important;
  font-weight: 600;
}
.kin {
  /*needed*/
  width: 35% !important;
}
