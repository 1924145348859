@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Dosis:wght@200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300;1,400&display=swap");
.bm-burger-button {
  position: fixed;
  display: none;
  width: 20px;
  height: 20px;
  right: 22px;
  top: 18px;
}
@media screen and (max-width: 1040px) {
  .bm-burger-button {
    display: block;
  }
}

.bm-burger-bars {
  background: #444444;
}
@media (min-width: 768px) {
  .bm-burger-button {
    top: 24px !important;
  }
}
@media screen and (max-width: 280px) {
  .bm-cross-button {
    position: absolute;
    right: 60px !important;
  }
  .bm-menu {
    width: 80%;
  }
  .fold {
    font-size: 14px;
    font-weight: 800 !important;
  }
}

.bm-burger-bars-hover {
  background: black;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #000;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #ebeaec;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #940c0c;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  padding: 40px;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.mobile-header {
  color: black;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}
.mobile-anchor {
  color: #000;
  padding-top: 45px !important;
  text-decoration: none;
}
@media (max-width: 280px) {
  .bm-burger-button {
    width: 15px;
    height: 15px;
    top: 18px;
  }
}
@media (min-width: 768px) {
  .bm-burger-button {
    top: 20px;
  }
  .take {
    margin-right: 19px !important;
  }
}
